@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 500 !important;
  font-style: normal !important;
  background-color: white !important;
}

.heading-color {
  color: #f1c22e !important;
}

.text-color {
  color: #2d4583 !important;
}

.link-styles {
  color: #f1c22e !important;
  cursor: pointer;
  text-decoration: none;
}

.link-styles:active {
  color: #f12ec7 !important;
}

.cards-border {
  height: fit-content;
}

.on-hover-effect:hover {
  background-color: #f7dd8a !important;
}

.d-flex-align-justify-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
